import styled from "styled-components";
// import { AccountButton } from "../../components/AccountButton";
import base from "../../assets/images/base.svg";
import opensea from "../../assets/images/opensea.svg";
import twitter from "../../assets/images/twitter.svg";
import Button from "../../components/Button";
import { useWindowSize } from "../../hooks/useWindowSize";
import "../../typography.css";

type navProps = {};

const Header = styled.header`
  position: sticky;
  z-index: 1;
  top: 0;
  max-width: 100vw;
  height: 80px;
  color: white;
  background-color: black;
  display: flex;
  padding: 0 5%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1080px) {
    flex-direction: column;
    height: auto;
    position: static;
`;

const HeaderLeft = styled.div`
  display: flex;
  font-family: pixel;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
  }
`;

const HeaderRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SmollBtn = styled.a`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 38px;
  height: 38px;
  margin-right: 10px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 3px solid #000;
  border-radius: 50%;
  background-color: #fff;
  font-family: Tanker, sans-serif;
  color: #000;
  font-size: 20px;
  font-weight: 400;
  transition: 0.2s ease;

  &:hover {
    transform: scale(0.9);
  }
`;

const NavItem = styled.a`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: pixel;
  font-size: 1.5em;
  margin: 1em;
  padding: 0.25em 0.5em;
  cursor: pointer;
`;

export const goTo = (section: string) => {
  const element = document.getElementById(section);
  const elementTop = element?.offsetTop;
  window.scrollTo({ top: elementTop, behavior: "smooth" });
};

const Navbar = (props: navProps) => {
  const { width } = useWindowSize() || { width: 0, height: 0 };

  return (
    <Header style={{ fontFamily: "pixel" }}>
      <HeaderLeft>
        <Button link onClick={() => goTo("landing")}>
          <div
            style={{
              display: "flex",
              alignItems: "center", // This aligns items vertically in the center
              justifyContent: "center", // This centers items horizontally
              fontSize: "1.5rem",
            }}
          >
            RDR Punks on{" "}
            <img
              src={base}
              alt="base"
              style={{ height: "30px", marginLeft: "8px" }}
            />
          </div>
        </Button>
      </HeaderLeft>
      <HeaderRight>
        <SmollBtn
          href="https://opensea.io"
          rel="noreferrer"
          target="_blank"
          className="df-btn-small df-btn-none w-inline-block"
        >
          <img src={opensea} loading="lazy" alt="" className="df-img-small" />
        </SmollBtn>
        {/* <SmollBtn
          href="https://discord.com/invite/rdrpunks"
          target="_blank"
          rel="noreferrer"
          className="df-btn-small df-btn-none w-inline-block"
        >
          <img src={discord} loading="lazy" alt="" className="df-img-small" />
        </SmollBtn> */}
        <SmollBtn
          href="https://twitter.com/rdrpunks"
          rel="noreferrer"
          target="_blank"
          className="df-btn-small df-btn-none w-inline-block"
        >
          <img src={twitter} loading="lazy" alt="" className="df-img-small" />
        </SmollBtn>
        {width && width > 1080 ? (
          <>
            <NavItem onClick={() => goTo("about")}>about</NavItem>
            <NavItem onClick={() => goTo("rdrpass")}>rdr pass</NavItem>
            <NavItem onClick={() => goTo("team")}>team</NavItem>
            <NavItem onClick={() => goTo("faq")}>faq</NavItem>
          </>
        ) : null}
      </HeaderRight>
    </Header>
  );
};

export default Navbar;
