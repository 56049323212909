import React from "react";
import ReactDOM from "react-dom";
import { DAppProvider, Rinkeby } from "@usedapp/core";
import App from "./App";
import "./reset.scss";
import "./index.css";

const config = {
  readOnlyChainId: Rinkeby.chainId,
  readOnlyUrls: {
    [Rinkeby.chainId]:
      "https://rinkeby.infura.io/v3/1bf05450c59f4185ab3606daa3fa8c9a",
  },
};

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <App />
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
