import React from 'react';
import styled from 'styled-components';

type buttonProps = {
  children: string | React.ReactElement;
  primary?: boolean;
  link?: boolean;
  onClick?: () => void;
}

const StyledButton = styled.button`
  background: ${(props: buttonProps) => props.primary ? '#1d1d1d' : 'transparent'};
  color: ${(props: buttonProps) => props.primary ? 'white' : '#ffffff'};
  font-family: pixel;
  font-size: 1.5em;
  margin: 1em;
  padding: 0.25em .5em;
  border: ${(props: buttonProps) => props.link ? 'none' : '2px solid #ffffff'};
  border-radius: 3px;
  cursor: pointer;
  transform: scale(1);
  transition: 0.2s ease-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const Button = (props: buttonProps) => {
  return (
    <StyledButton
      {...props}
    />
  );
}

export default Button;