import React from "react";
import styled from "styled-components";
import bgDesktop from "../../assets/images/bg.png";

type landingProps = {};

const SectionContainer = styled.div`
  @keyframes horizontal {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100vw -50vh;
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  max-width: 100vw;
  color: black;
  flex-direction: column;
  background-image: url(${bgDesktop});
  animation: horizontal 30s linear 0s infinite forwards;
`;

const Landing = (props: landingProps) => {
  return <SectionContainer></SectionContainer>;
};

export default Landing;
