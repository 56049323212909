import styled from "styled-components";

const Header = styled.header`
  position: sticky;
  top: 0;
  max-width: 100vw;
  height: 80px;
  color: white;
  background-color: black;
  display: flex;
  padding: 0 5%;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 80vw;
  width: 100%;
`;
const TwitterLink = styled.a`
  color: white;
  text-decoration: none;
  :visited {
    color: #800000;
  }
`;

const Rarity = styled.div`
  vertical-align: middle !important;
  display: flex;
  align-items: center;
`;

const Footer = () => {
  return (
    <Header>
      <HeaderInner>
        <p>
          made with ♡ by{" "}
          <TwitterLink href="https://twitter.com/">Jeff</TwitterLink> for{" "}
          <TwitterLink href="https://twitter.com/@rdrpunks">
            RDR Punks
          </TwitterLink>
          .
        </p>
        {/* <Rarity>
          <p>
            find us on &nbsp;{" "}
            <a href="https://verify.raritysniper.com/">Rarity Sniper</a> and
            &nbsp;
          </p>
          <a href="https://icy.tools/calendar" target="_blank">
            {" "}
            <img
              src="https://cdn.icy.tools/images/icy-tools-dark.svg"
              alt="as seen on icy.tools"
              width="128"
            />
          </a>
        </Rarity> */}
      </HeaderInner>
    </Header>
  );
};

export default Footer;
