import React from "react";
import styled from "styled-components";

const Heading = styled.h2`
  color: black;
  background-color: white;
  padding: 15px;
  margin-bottom: 0;
  padding: 0;
  display: flex;
  height: 50px;
  font-size: 1.5rem;
`;

interface ContentProps {
    open: boolean;
  }

const Content = styled.div<ContentProps>`
  border-top: none;
  opacity: ${props => (props.open ? "1" : "0")};
  max-height: ${props => (props.open ? "100%" : "0")};
  overflow: hidden;
  padding: ${props => (props.open ? "15px" : "0 15px")};
  transition: all 0.3s;
  background-color: #fafafa;
  color: black;
  
  p {
    margin: 0;
  }
`;


const CollapsibleStyle = styled.div`
  margin-bottom: 15px;
  cursor: pointer;
  width: 90%;
  background-color: #fafafa;
  border: 2px solid #800000;
  border-radius: 3px;
  `

const StartBlock = styled.div`
  background-color: #800000;
  width: 1.5vw;
  height: 100%;
  padding-left: 0;
  margin-left: 0;
  padding-top: 10px;

  `;

const TextBlock = styled.div`
  height: 100%;
  padding-left: 10px;
  margin-left: 0;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  `;

interface CollapsibleProps {
    question: string;
    answer?: string;
    children?: React.ReactNode;
}

const Collapsible : React.FC<CollapsibleProps>= ({ question, answer, children}) => {
    const [open, setOpen] = React.useState(false);
    
    return (
        <CollapsibleStyle>
        <Heading onClick={() => setOpen(!open)}>
          <StartBlock></StartBlock>
           <TextBlock>{question}</TextBlock>
        </Heading>
        <Content open={open}>
            {answer || children}
        </Content>
        </CollapsibleStyle>
    );
}

export default Collapsible;