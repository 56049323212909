import styled from "styled-components";
import punk10 from "../../assets/images/punk10.png";
import rdrpass from "../../assets/images/rdrpass.gif";
import Layout from "../../components/Layout";

type RDRPassProps = {};

const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  padding-top: 5vh;
  background-image: url(${punk10});
  background-color: rgba(159, 105, 64, 255);
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
  @media (max-width: 1080px) {
    flex-direction: column;
    height: auto;
  }
`;

const RDRPassHeader = styled.h1`
  font-size: 5rem;
  text-align: left;
  justify-self: flex-start;
  width: 80vw;
  color: white;
  padding-top: 15vh;
  @media (max-width: 1080px) {
    padding-top: 5vh;
  }
`;

const RDRPassText = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  max-width: 40vw;
  padding: 1vw;
  h1 {
    font-size: 2rem;
    color: white;
  }
  p {
    font-size: 2rem;
    margin: 10px;
  }
  @media (max-width: 1080px) {
    flex-direction: column;
    max-width: 80vw;
    padding: 5vh 0vh;
    p {
      font-size: 1.5rem;
    }
  }
`;

const RDRPassImage = styled.img`
  width: 30%;
  margin: 1vw;
  border-radius: 3px;
  @media (max-width: 1080px) {
    width: 83vw;
    margin: 1vw;
  }
`;

const RDRPassContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  color: white;
  padding-bottom: 1vh;
  h1 {
    font-size: 2rem;
  }
  @media (max-width: 1080px) {
    flex-direction: column-reverse;
    height: auto;
  }
`;

// MintBox Component
const MintBoxButton = styled.button`
  font-family: pixel;
  font-size: 2.5em;
  text-align: center;
  background-color: white;
  color: black;
  border: 2px solid black;
  opacity: 0.8;
  padding: 15px;
  margin-top: 20px;
  cursor: pointer;
  max-width: 300px;
  align-self: center;
  &:hover {
    transform: scale(1.1);
  }
`;

const RDRPass = (props: RDRPassProps) => {
  return (
    <Layout id="rdrpass">
      <SectionContainer>
        <RDRPassHeader>RDR Pass</RDRPassHeader>
        <RDRPassContainer>
          <RDRPassText>
            <p>
              It's a free pass with WL utility, allowing you to mint RDR Punk
              before the public sale.
            </p>
            <p>
              X passes = X RDR Punks per wallet on WL phase. (1 pass = 1 punk; 2
              passes = 2 punks, etc.)
            </p>
            <MintBoxButton
              onClick={() => {
                window.open("https://opensea.io/collection/rdr-pass");
              }}
            >
              Buy on secondary.
            </MintBoxButton>
          </RDRPassText>
          <RDRPassImage src={rdrpass} alt="RDR Pass image" />
        </RDRPassContainer>
      </SectionContainer>
    </Layout>
  );
};

export default RDRPass;
