import styled from "styled-components";
import punk3 from "../../assets/images/punk2.png";
import punk2 from "../../assets/images/punkbg1.png";
import Layout from "../../components/Layout";

type aboutProps = {};

const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  padding-top: 5vh;

  background-image: url(${punk3});
  background-color: rgba(163, 51, 51, 255);
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);

  @media (max-width: 1080px) {
    flex-direction: column;
    height: auto;
  }
`;

const AboutHeader = styled.h1`
  font-size: 5rem;
  text-align: left;
  justify-self: flex-start;
  width: 80vw;
  color: white;
  padding-top: 15vh;
  @media (max-width: 1080px) {
    padding-top: 5vh;
  }
`;

const AboutText = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  max-width: 40vw;
  padding: 1vw;

  h1 {
    font-size: 2rem;
    color: white;
  }

  p {
    font-size: 2rem;
    margin: 10px;
  }

  @media (max-width: 1080px) {
    flex-direction: column;
    max-width: 80vw;
    padding: 5vh 0vh;
    p {
      font-size: 1.5rem;
    }
  }
`;
const AboutPunk = styled.img`
  width: 30%;
  margin: 1vw;
  border-radius: 3px;

  @media (max-width: 1080px) {
    flex-direction: column;
    width: 83vw;
    margin: 1vw;
  }
`;
const AboutContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  color: white;
  padding-bottom: 1vh;
  h1 {
    font-size: 2rem;
  }
  @media (max-width: 1080px) {
    flex-direction: column-reverse;
    height: auto;
  }
`;

const About = (props: aboutProps) => {
  return (
    <Layout id="about">
      <SectionContainer>
        <AboutHeader>About</AboutHeader>
        <AboutContainer>
          <AboutText>
            <p>
              {" "}
              RDR Punks is a PFP collection of 3,000 cowboy punks on Base chain.
              Created using over 110 traits in five categories: Bandits,
              Sheriffs, Indians, Merchants, and Farmers. Each punk captures the
              essence of its character with a direct gaze and distinct identity.
            </p>
            <p>
              {" "}
              Inspired by Red Dead Redemption 2, RDR Punks blends classic and
              modern influences to build a punk derivative with Wild West vibe
              and appearance.{" "}
            </p>
          </AboutText>
          <AboutPunk src={punk2} alt="desktop" className="desktop-image" />
        </AboutContainer>
      </SectionContainer>
    </Layout>
  );
};

export default About;
