import React from "react";
import styled from "styled-components";
import bgDesktop from "../../assets/images/desktop.png";
import bgMobile from "../../assets/images/mobilebg2.png";
import LogoComponent from "../../components/LogoComponent";
import MintBox from "../../components/mintbox/MintContent";
import { goTo } from "../../components/navbar/Navbar";

const SectionContainer = styled.div`
  display: flex;
  width: 100%;
  color: black;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color:  rgb(171,28,27);
  background-image: url(${bgDesktop});
  height: 100vh;

  @media (max-width: 1080px) {
    background-position: center;
    background-image: url(${bgMobile});
    background-size: contain;
    height: auto;
`;

const MintContainer = styled.div`
  display: flex;
  justify-self: flex-start;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  height: 80vh;
  width: 50%;
  @media (max-width: 1080px) {
    padding-top:20px;
    width: 100%;
  `

const ScrollDown = styled.p`
  font-size: 2rem;
  padding-top: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  animation: mover 1s infinite  alternate;

  @-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
  `

const Landing = () => {
  return <SectionContainer id="landing">
    <MintContainer>
      <LogoComponent style={{ padding : '0px', margin : '0px'}}/>
      <MintBox/>
      <ScrollDown onClick={() => goTo("about")}><p>Scroll Down</p><p>⌄</p></ScrollDown>
    </MintContainer>
  </SectionContainer>;
};

export default Landing;
