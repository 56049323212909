import styled from "styled-components";

const MintContainer = styled.div`
  display: flex;
  justify-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 400px;
  width: 30vw;
  padding: 40px;
  background-color: black !important;
  color: white !important;
  border-radius: 3px;
  border: 2px solid white;
  opacity: 0.8;
  @media (max-width: 1080px) {
    width: 80%;
  }
`;
const MintingContent = styled.div`
  display: flex;
  margin: 25px;
  flex-direction: column;
  p {
    padding: 10px;
  }
  text-align: center;
  h1 {
    font-size: 2rem;
  }
`;

const MintBoxHeader = styled.h1`
  font-size: 2.5em;
  text-align: center;
`;

const MintBoxButton = styled.button`
    font-family: pixel;
    font-size: 2.5em;
    text-align: center;
    background-color: white;
    color: black;
    border: 2px solid black;
    opacity: 1;
    padding: 15px;
    margin-top: 20px;
    cursor: pointer;
    &:hover: {
    transform: scale(1.1);
    }
}
    `;

const MintBox = () => {
  return (
    <MintContainer>
      <MintBoxHeader>Mint a RDR Punk</MintBoxHeader>
      <MintingContent>
        <p>Mint date: April 25th</p>
        <p>Total supply: 3000 </p>
        <p>WL Mint price: 0.0039Ξ</p>
        <p>Public Mint price: 0.0045Ξ</p>
        <MintBoxButton
          onClick={() => {
            window.open("http://opensea.io/collection/rdrpunks/overview");
          }}
        >
          Mint here
        </MintBoxButton>
      </MintingContent>
    </MintContainer>
  );
};
//0.06 Ξ
export default MintBox;

//window.location.assign("http://mint.rdrpunks.io");
