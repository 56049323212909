import React, { ReactNode } from "react";
import styled from "styled-components";

type layoutProps = {
  children: ReactNode;
  id?: string;
  center?: boolean;
};

const Section = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props: layoutProps) =>
    props.center ? "center" : "flex-start"};
  align-items: center;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Layout = (props: layoutProps) => {
  return <Section {...props}>{props.children}</Section>;
};

export default Layout;
