import styled from "styled-components";
import punk3 from "../../assets/images/chris.png";
import punk from "../../assets/images/phoenix.png";
import Layout from "../../components/Layout";
// import punk5 from "../../assets/images/punk4.png";
import punk4 from "../../assets/images/punk5.png";
import twitter from "../../assets/images/twitter.svg";
import { SmollBtn } from "../../components/navbar/Navbar";

type aboutProps = {};

type member = {
  name: string;
  role: string;
  image: string;
  twitter: string;
  bio?: string;
};

const members: member[] = [
  {
    name: "Paveci",
    role: "Founder",
    twitter: "https://twitter.com/paveci",
    image: punk,
    bio: "AI artist, creator of Based Cubes & The Sapphires",
  },
  {
    name: "Cristian Godoy",
    role: "Artist",
    twitter: "https://twitter.com/CristianGodoyX",
    image: punk3,
    bio: "Pixel artist, creator of Pixel Queens",
  },
  // {
  //     name: "Frank",
  //     role: "Smart Contract Developer",
  //     twitter : "https://twitter.com/frankNFT_eth",
  //     image: punkbg
  // },
  // {
  //   name: "Jeff",
  //   role: "Developer",
  //   twitter: "https://twitter.com",
  //   image: punk4,
  // },
  // {
  //     name: "DuoCrypto",
  //     role: "Marketing Advisor",
  //     twitter : "https://twitter.com/ADuocrypto",
  //     image: punk6
  // },
  // {
  //     name: "ArchiNFT ",
  //     role: "Community manager",
  //     twitter : "https://twitter.com/TuianaBasanova",
  //     image: punk7
  // },
  // {
  //   name: "Nivore",
  //     role: "Community manager",
  //   twitter: "https://twitter.com/nivore_eth",
  //     image: punk9
  // },
];

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  max-width: 100vw;

  background-image: url(${punk4});
  background-color: rgba(163, 51, 94, 255);
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  box-shadow: inset 0 0 0 100vw rgba(0, 0, 0, 0.8);
  h1 {
    color: white;
  }
  @media (max-width: 1080px) {
    height: auto;
    min-height: 100vh;
  }
`;

const TeamHeader = styled.h1`
  font-size: 5rem;
  text-align: left;
  width: 80vw;
  color: white;
  padding-top: 15vh;
  @media (max-width: 1080px) {
    padding-top: 5vh;
  }
`;

const TeamMembers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  width: 90vw;
  padding: 1vw;
  flex-base: 33.33%;
  flex-wrap: wrap;

  @media (max-width: 1080px) {
    flex-base: 33.33%;
    flex-wrap: wrap;
  }
`;

const TeamMember = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  max-width: 40vw;
  padding: 1vw;
  margin: 1vw;
  flex: 0 0 calc(25% - 10px);
  flex-wrap: wrap;
  max-width: 25%;

  @media (max-width: 786px) {
    max-width: 80vw;
    flex: 1 calc(50% - 10px);
    flex-wrap: wrap;
  }
`;

const TeamMemberName = styled.h1`
  padding: 1vw;
  font-size: 2rem;
  text-align: center;
`;

const TeamMemberRole = styled.h1`
  font-size: 1rem;
  text-align: center;
`;

const TeamMemberBio = styled.p`
  font-size: 1rem;
  text-align: center;
`;

const TeamMemberImage = styled.img`
  width: 80%;
  border-radius: 3px;
  height: auto;
`;

const Team = (props: aboutProps) => {
  return (
    <Layout id="team">
      <SectionContainer>
        <TeamHeader>Team</TeamHeader>
        <TeamMembers>
          {members.map((member, index) => {
            return (
              <TeamMember key={index}>
                <TeamMemberImage src={member.image} alt={member.name} />
                <TeamMemberName>{member.name}</TeamMemberName>
                <TeamMemberRole>{member.role}</TeamMemberRole>
                {member.bio && <TeamMemberBio>{member.bio}</TeamMemberBio>}
                <SmollBtn
                  href={member.twitter}
                  rel="noreferrer"
                  target="_blank"
                  className="df-btn-small df-btn-none w-inline-block"
                >
                  <img
                    src={twitter}
                    loading="lazy"
                    alt=""
                    className="df-img-small"
                  />
                </SmollBtn>
              </TeamMember>
            );
          })}
        </TeamMembers>
      </SectionContainer>
    </Layout>
  );
};

export default Team;
