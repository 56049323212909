import React from "react";
import styled from "styled-components";

import Sep from "../assets/images/rdrWhite.png";

const SeperatorInner = styled.div`
background-image: url(${Sep});
background-color: white;
background-size: contain;
background-repeat: repeat-x;
height: 30px;
padding-left: 5px;
@keyframes hor {
  0% {
    background-position: 100vw 0;
  }
  100% {
    background-position: 0 0;
  }
}
animation: hor 15s linear 0s infinite backwards;
`;

const SeperatorWhite = () => {
  return <SeperatorInner />;
};

export default SeperatorWhite;
