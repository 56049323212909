//import { useEthers } from "@usedapp/core";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";

import SeperatorBlack from "./components/SeperatorBlack";
import SeperatorWhite from "./components/SeperatorWhite";
import { TopErrorMessage } from "./components/TopErrorMessage";
import Navbar from "./components/navbar/Navbar";
import About from "./sections/about/About";
import Faq from "./sections/faq/Faq";
import Footer from "./sections/footer/Footer";
import Landing from "./sections/landing/Landing";
import Punks from "./sections/punks/Punks";
import RDRPass from "./sections/rdrpass/RDRPass";
import Team from "./sections/team/Team";

const SiteContainer = styled.div``;

function App() {
  // const { chainId } = useEthers();
  return (
    <SiteContainer>
      <TopErrorMessage />
      <Navbar />
      <Landing />
      <SeperatorWhite />
      <Punks />
      <SeperatorBlack />
      <RDRPass />
      <SeperatorWhite />
      <Punks />
      <SeperatorBlack />
      <About />
      <SeperatorWhite />
      <Punks />
      <SeperatorBlack />
      <Team />
      <SeperatorWhite />
      <Punks />
      <SeperatorBlack />
      <Faq />
      <Footer />
    </SiteContainer>
  );
}

export default App;
