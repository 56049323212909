import React, { useEffect, useState } from "react";
import { useEthers } from "@usedapp/core";
import styled from "styled-components";

const ChainWarning = styled.div`
  color: red;
  margin-right: 40px;
  margin-left: 40px;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const TopErrorMessage = () => {
  const { error } = useEthers();
  const [activateError, setActivateError] = useState("");
  
  useEffect(() => {
    if (error) {
      setActivateError(error.message);
    }
  }, [error]);

  if (error) {
    return <ChainWarning>{activateError}</ChainWarning>;
  }

  if (error) {
    return <ChainWarning>{activateError}</ChainWarning>;
  }
  return <></>;
};
