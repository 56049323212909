import styled from "styled-components";
import punk from "../../assets/images/punk3.png";
import Layout from "../../components/Layout";
import Collapsible from "../../components/collapsible/Collapsible";

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  background: url(${punk}) no-repeat right;
  background-color: rgba(112, 127, 58, 0.7);
  background-size: contain;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
  color: white;
  @media (max-width: 1080px) {
    height: auto;
  }
`;

const FaqTitle = styled.h1`
  font-size: 5rem;
  width: 80vw;
  text-align: left;
  padding-top: 5vh;
  @media (max-width: 1080px) {
    font-size: 3rem;
  }
`;

const CollapsibleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  h1 {
    font-size: 2rem;
  }
  @media (max-width: 1080px) {
    padding-top: 5vh;
    height: auto;
  }
`;

const Faq = () => (
  <Layout id="faq">
    <SectionContainer>
      <FaqTitle>FAQ</FaqTitle>
      <CollapsibleContainer>
        <Collapsible
          question="What is the Holders Phase?"
          answer="For Based Cubes holders, guaranteeing 2X RDR Punks per cube owned. Minting is free and lasts 1 hour. A snapshot will be taken on April 24 to verify eligibility."
        />
        <Collapsible
          question="What is the WL Phase?"
          answer="For RDR Pass holders, giveaway winners, and collab partners. Each pass allows minting 2X RDR Punks at a price of 0.0039 ETH per punk, lasting for 1 hour. Snapshot on April 24."
        />
        <Collapsible
          question="What is the Public Phase?"
          answer="Open to everyone, if inventory remains after prior phases. Punks priced at 0.0045 ETH, available until sold out."
        />
        <Collapsible
          question="When is the launch?"
          answer="Official minting starts at 4pm UTC on April 25 via OpenSea. Keep an eye on our Twitter for real-time updates."
        />
        <Collapsible
          question="Where can I mint RDR Punks?"
          answer="Minting will be available on the launch date via the OpenSea mint page linked on our official Twitter."
        />
        <Collapsible
          question="What benefits do Based Cubes holders receive?"
          answer="Based Cubes holders can mint for free during the Holders phase. If you hold X Based Cubes, you'll get 2X free mints. Current holder count is approximately 230."
        />
        <Collapsible
          question="What benefits do RDR Pass holders receive?"
          answer="All RDR Pass holders are guaranteed whitelist spots. A snapshot for eligibility will be taken soon."
        />
        <Collapsible
          question="Mint details?"
          answer="Total supply: 3,000. Price: 0.0039 ETH. Detailed phases and dates will be announced."
        />
        <Collapsible
          question="Is it possible to get on the whitelist without buying an RDR Pass or Based Cube?"
          answer="Yes, join our alpha/holders group for collaboration spots or participate in our giveaways to potentially receive whitelist spots."
        />
      </CollapsibleContainer>
    </SectionContainer>
  </Layout>
);

export default Faq;
